import React, { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { Outlet, useParams, useNavigate } from "react-router-dom"
import { FormApi } from "../Services/FormApi";

export const NewFormPage = () => {
    const { id } = useParams();
    const msal = useMsal();
    const navigate = useNavigate();
    
    console.log("NewFormPage called");
    useEffect(() => {
        const fetchData = async () => {
            console.log(`Fetching form template ${id}`);
            if (id) {
                const api = new FormApi(msal);
                const data = await api.getForm(id);
                navigate('0', {state: data, replace: true});
            }
        }
        fetchData();
    }, [])

    return (
        <Outlet />
    )
}
