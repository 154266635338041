import * as React from "react";
import { useMsal } from "@azure/msal-react";
import { FormApi } from "../Services/FormApi";
import { FormCategory } from "../Models/FormModels";
import { Link } from "@fluentui/react-components";



export const FormTemplateOptions = () => {
    const msal = useMsal();
    const [options, setOptions] = React.useState<FormCategory[]>([]);
    React.useEffect(() => {
        const fetchData = async () => {
            const api = new FormApi(msal);
            const data = await api.getFormOptions("reports-desktop");
            setOptions(data.categories);
        }
        fetchData();
    }, [msal]);
    return (<div className="formConatiner">
        {
            options.map((category, index) => {
                return (<div key={index.toString()} ><h2>{category.name}</h2>
                {
                    category.items.map((item, index2) => {
                       return (<div key={`${index}-${index2}`}><Link href={`NewForm/${item.reportId}`}>{item.displayName}</Link></div>) 
                    })
                }
                </div>);
            })
        }
    </div>);
}
