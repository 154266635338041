import { IMsalContext } from "@azure/msal-react";
import { FormTemplate, FormOptions } from "../Models/FormModels"
import { apiConfig, loginRequest } from "../authConfig";

export class FormApi {
    private msal: IMsalContext;

    constructor (msal: IMsalContext ) {
        this.msal = msal;
      }

    async getToken() {
        var token = window.localStorage.getItem('access_token');
        if (token) { return token; }
        var result = await this.msal.instance.acquireTokenSilent(loginRequest);
        return result.accessToken;
    }

    async getForm(docType: string) {
        
        var accessToken = await this.getToken();
        console.log(`Gettting ${docType}`);

        if (accessToken) {
            if (accessToken.length == 0) {
                console.log(`accessToken is empty`);
            }
        }
        else {
            console.log(`accessToken is undefined`);
        }

        var url = `${apiConfig.apiEndpoint}/api/Assessments/Templates?templateId=${docType}`;
        let response = await fetch(url, {
            method: 'get',
            headers: new Headers({
                'Authorization': `bearer ${accessToken}`
            })
        });
        const resultText = await response.text();
        console.log(`${resultText}`);
        const data = JSON.parse(resultText) as FormTemplate;
        return data;
    }

    async getFormOptions(reportId: string) {
        var accessToken =  await this.getToken();
        var url = `${apiConfig.apiEndpoint}/ReferenceData/ReportCategories?reportId=${reportId}`;
        let response = await fetch(url, {
            method: 'get',
            headers: new Headers({
                'Authorization': `bearer ${accessToken}`
            })
        });
        const data: FormOptions = await response.json();
        return data;
    }
}
